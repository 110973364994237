/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useUpdateEventMutation } from "../../features/api/apiYeapGo";
import swal from "sweetalert";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import { userAtom } from "../../features/user/userAtom";
export const DialogEvents = ({ open, setOpen, eventsID }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    EVE_MonitorAlarmado: "",
    EVE_TicketProveedor: "",
    EVE_TicketInterno: "",
    EVE_AreaEspecialista: "",
  });
  const [user] = useAtom(userAtom);
  const { Username, Token } = user;

  const [updateEvent] = useUpdateEventMutation();
  const gestionar = () => {
    if (!eventsID || eventsID.length === 0) {
      setOpen(false);
      swal("Debe seleccionar al menos un evento", "", "error");
      return;
    }
    if (
      data.EVE_MonitorAlarmado === "" ||
      data.EVE_TicketProveedor === "" ||
      data.EVE_TicketInterno === "" ||
      data.EVE_AreaEspecialista === ""
    ) {
      swal("Deben llenarse todos los campos", "", "error");
    } else {
      for (let index = 0; index < eventsID.length; index++) {
        updateEvent({
          Token,
          id: eventsID[index],
          body: {
            ...data,
            EVE_Operador: Username,
          },
        })
          .unwrap()
          .then(() => {
            enqueueSnackbar(
              `Evento ${eventsID[index]} gestionado correctamente`,
              { variant: "success" }
            );
          })
          .catch((err) => {
            enqueueSnackbar(
              `Evento ${eventsID[index]} no pudo ser gestionado`,
              { variant: "error" }
            );
          });
      }
      setData({
        EVE_MonitorAlarmado: "",
        EVE_TicketProveedor: "",
        EVE_TicketInterno: "",
        EVE_AreaEspecialista: "",
      });
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tipoEvento = [
    {
      value: "Ticket",
      label: "Ticket",
    },
    {
      value: "CCT",
      label: "CCT",
    },
    {
      value: "Informativo",
      label: "Informativo",
    },
  ];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}>
      <DialogTitle>Gestionar masivamente</DialogTitle>
      <DialogContentText></DialogContentText>
      <DialogContent>
        <TextField
          required
          size="small"
          fullWidth
          value={data.EVE_MonitorAlarmado || ""}
          label="EVE_MonitorAlarmado"
          onChange={(e) =>
            setData({
              ...data,
              EVE_MonitorAlarmado: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogContent>
        <TextField
          required
          size="small"
          fullWidth
          value={data.EVE_TicketProveedor || ""}
          label="EVE_TicketProveedor"
          onChange={(e) =>
            setData({
              ...data,
              EVE_TicketProveedor: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogContent>
        <TextField
          required
          size="small"
          fullWidth
          value={data.EVE_TicketInterno || ""}
          label="EVE_TicketInterno"
          onChange={(e) =>
            setData({
              ...data,
              EVE_TicketInterno: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogContent>
        <TextField
          required
          size="small"
          fullWidth
          value={data.EVE_AreaEspecialista || ""}
          label="EVE_AreaEspecialista"
          onChange={(e) =>
            setData({
              ...data,
              EVE_AreaEspecialista: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={gestionar}>Gestionar</Button>
      </DialogActions>
    </Dialog>
  );
};

import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import { ReceiptLongOutlined, Sensors } from "@mui/icons-material";
import { QueryStats } from "@mui/icons-material";

const ListItem = ({ text, link, Icon }) => {
  const navigate = useNavigate();
  return (
    <ListItemButton onClick={() => navigate(link)}>
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export const MainListItems = () => {
  const [user] = useAtom(userAtom);
  return (
    <>
      {user.Rol !== "Administrador" ? (
        <></>
      ) : (
        <>
          <ListItem
            text="Gestión de usuarios"
            link="usuarios"
            Icon={<PeopleIcon></PeopleIcon>}
          ></ListItem>
          <ListItem
            text="Gestión de sensores"
            link="sensores"
            Icon={<Sensors />}
          ></ListItem>
        </>
      )}

      <ListItem
        text="Gestión de eventos"
        link="eventos"
        Icon={<EventNoteIcon />}
      ></ListItem>

      <ListItem
        text="Estadisticas eventos"
        link="estadisticas"
        Icon={<BarChartIcon />}
      ></ListItem>
      {user.Rol !== "Administrador" ? (
        <></>
      ) : (
        <>
          <ListItem
            text="Estadisticas analistas"
            link="estadisticas/Analistas"
            Icon={<QueryStats />}
          ></ListItem>
        </>
      )}

      <ListItem
        text="Reporte de eventos"
        link="reporte"
        Icon={<ReceiptLongOutlined />}
      ></ListItem>
    </>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton> */}
  </React.Fragment>
);

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import axios from "axios";
import LogoTP from "../../assets/images/Logo_Yeapp.png";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
const URL = process.env.REACT_APP_API_URL;
export const Login = () => {
  let navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [data, setData] = useState({ username: "", password: "" });

  useEffect(() => {
    if (user.Username) {
      navigate("/");
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { username, password } = data;
    if (username === "" || password === "") {
      swal("Oops", "Es necesario llenar los campos!", "error");
    }
    const res = axios
      .post(`${URL}auth`, {
        user: username,
        password: password,
      })
      .then((res) => {
        const { Token, user, rol, name } = res.data;
        setUser({ Token, Username: user, Rol: rol, Name: name });
        swal(
          `Bienvenido(a) ${name}!`,
          "Sesión iniciada correctamente",
          "success"
        );
      })
      .catch((e) => swal("Error", e.response.data, "error"));
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid
        item
        xs={0}
        md={7}
        sx={{
          marginTop: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={LogoTP} width={"50%"} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Inicia sesión en YeappGO!
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuario"
              name="username"
              value={data.username}
              onChange={(e) => setData({ ...data, username: e.target.value })}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar sesión
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

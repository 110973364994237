import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./components/login/Login";
import { useAtom } from "jotai";
import Dashboard from "./components/dashboard/Dashboard";
import { userAtom } from "./features/user/userAtom";

function App() {
  const [user] = useAtom(userAtom);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={user.Token ? <Dashboard /> : <Login />}
        ></Route>
        <Route path="login" element={<Login></Login>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

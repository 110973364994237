import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import Chart from "react-apexcharts";
import {
  useGetOperatorsQuery,
  useGetTiempoGestionStatisticsQuery,
} from "../../features/api/apiYeapGo";
import Moment from "moment";
import { useEffect, useState } from "react";
export const STEventsTime = ({ dateRange }) => {
  const [date, setDate] = useState({
    fechaInicial: "2018-08-01",
    fechaFinal: "3000-12-01",
  });
  const [operador, setOperador] = useState("");
  const [tipoTicket, setTipoTicket] = useState("");
  const [user] = useAtom(userAtom);
  const { Token } = user;
  const { data: listaOperadores, isLoading: isLoadingOp } =
    useGetOperatorsQuery({
      Token,
    });
  const { data, isLoading } = useGetTiempoGestionStatisticsQuery({
    Token,
    ...date,
    Operador: operador,
    TipoTicket: tipoTicket,
  });

  useEffect(() => {
    if (dateRange.startDate) {
      setDate({
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);

  useEffect(() => {
    if (listaOperadores) {
      setOperador(listaOperadores[0]);
    }
  }, [listaOperadores]);

  const state = {
    series: [
      {
        name: "Tiempo de gestión en minutos",
        data: data?.map((d) => Math.round(d.TiempoGestion / 60) || 0) || [],
      },
    ],
    options: {
      chart: {
        type: "area",
        stacked: false,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: true,
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: data?.map((d) => d.FechaAlerta) || [],
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm",
        },
      },
    },
  };

  return (
    <Accordion
      defaultExpanded={false}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography gutterBottom variant="h5" component="div">
          Tiempo de gestión por analista
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {!isLoadingOp ? (
          <>
            <FormControl fullWidth>
              <InputLabel id="select-operador">Operador</InputLabel>
              <Select
                id="select-operador"
                value={operador}
                onChange={(e) => {
                  setOperador(e.target.value);
                }}
              >
                {listaOperadores.map((o) => {
                  return (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="select-tipoTicket">Tipo ticket</InputLabel>
              <Select
                id="select-tipoTicket"
                value={tipoTicket}
                onChange={(e) => {
                  setTipoTicket(e.target.value);
                }}
              >
                <MenuItem value="">Todos los eventos</MenuItem>
                <MenuItem value="Ticket">Ticket</MenuItem>
                <MenuItem value="CCT">CCT</MenuItem>
                <MenuItem value="Informativo">Informativo</MenuItem>
              </Select>
            </FormControl>
            {isLoading ? (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="secondary" />
              </Grid>
            ) : data.length > 0 ? (
              <Chart
                options={state.options}
                series={state.series}
                type="line"
                height={"400rem"}
              />
            ) : (
              <>No hay datos disponibles</>
            )}
          </>
        ) : (
          <></>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

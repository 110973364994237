import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useAtom } from "jotai";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import LogoTP from "../../assets/images/YEC_Logo.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems, secondaryListItems } from "./listItems";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Users } from "../users/users";
import AccountMenu from "./AccountMenu";
import { useSelector } from "react-redux";
import { Events } from "../events/Events";
import { Event } from "../events/Event";
import { Statistics } from "../statistics/Statistics";
import { Sensores } from "../sensores/Sensores";
import { StatisticsAnalists } from "../statistics/StatisticsAnalists";
import { EventsReport } from "../reports/EventsReport";
import { userAtom } from "../../features/user/userAtom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function DashboardContent() {
  const [user] = useAtom(userAtom);
  const [open, setOpen] = React.useState(true);
  let navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (!user.Token) {
      navigate("login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} color="primary">
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src={LogoTP} alt={"Teleperformance"} width={50} />
          <Box sx={{ flexGrow: 1 }} marginLeft="10px">
            <Typography
              component="span"
              variant="h6"
              noWrap
              color="inherit"
              sx={{ fontWeight: "bold" }}
            >
              Yeapp
            </Typography>
            <Typography
              component="span"
              variant="h6"
              noWrap
              color="secondary"
              sx={{ fontWeight: "bold" }}
            >
              Go!
            </Typography>
          </Box>

          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav" color="secondary">
          <MainListItems />
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 4, mb: 4, ml: 1, mr: 1 }}>
          <Routes>
            <Route path="eventos/:id" element={<Event />}></Route>
            <Route path="usuarios" element={<Users />}></Route>
            <Route path="eventos" element={<Events />}></Route>
            <Route
              path="estadisticas/Analistas"
              element={<StatisticsAnalists />}
            ></Route>
            <Route path="reporte" element={<EventsReport />}></Route>
            <Route path="estadisticas" element={<Statistics />}></Route>
            <Route path="sensores" element={<Sensores />}></Route>
            <Route
              path="/*"
              element={<Navigate to="eventos" replace />}
            ></Route>
          </Routes>
        </Container>
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import {
  useGetEventQuery,
  useUpdateEventMutation,
} from "../../features/api/apiYeapGo";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { ArrowBack, BuildOutlined, ExpandMore } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { SensorBehavior } from "./SensorBehavior";
import { SensorBehaviorHour } from "./SensorBehaviorHour";
import { SensoresNodo } from "./SensoresNodo";
import { SensoresNodoGrafica } from "./SensoresNodoGrafica";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";

const events = [
  {
    ip: "21.119.187.127",
    id: 12345,
    time: "02/04/2022 01:52",
    name: "Página web de la institución caida",
    pingResult: "Ping Fallido",
    remediationResult: "Fallo",
    source: "Pingdom",
  },
  {
    ip: "21.119.187.127",
    id: 67890,
    time: "23/02/2022 12:15",
    name: "Servidor de correo electrónico inaccesible",
    pingResult: "Ping Fallido",
    remediationResult: "Fallo",
    source: "Orion",
  },
  {
    ip: "21.119.187.127",
    id: 24680,
    time: "13/11/2022 07:30",
    name: "Servicio de base de datos no disponible",
    pingResult: "Ping Fallido",
    remediationResult: "Exito",
    source: "Zabbix",
  },
  {
    ip: "21.119.187.127",
    id: 13579,
    time: "14/06/2022 18:45",
    name: "Problema de acceso remoto",
    pingResult: "Ping Fallido",
    remediationResult: "Fallo",
    source: "Datadog",
  },
  {
    ip: "21.119.187.127",
    id: 24601,
    time: "02/05/2022 09:30",
    name: "Problema de red interna",
    pingResult: "Ping Fallido",
    remediationResult: "Fallo",
    source: "Pingdom",
  },
  {
    ip: "21.119.187.127",
    id: 97531,
    time: "09/09/2022 22:00",
    name: "Servicio de firewall no disponible",
    pingResult: "Ping Fallido",
    remediationResult: "Exito",
    source: "Zabbix",
  },
];

export const Event = () => {
  let { id } = useParams();
  const [user] = useAtom(userAtom);
  const { Username, Token } = user;
  const navigate = useNavigate();
  const {
    data: dataQuery,
    error,
    isLoading,
  } = useGetEventQuery({ Token, id: id });
  const [updateEvent, { isSuccess, isError }] = useUpdateEventMutation();
  // const { data: dataNodo, isLoading: isLoadingNodo } = useGetHistoricoNodoQuery(
  //   {
  //     Token,
  //     Nodo: dataQuery?.EVE_IPNodo,
  //     Sensor: dataQuery?.EVE_Sensor,
  //   },
  //   { skip: isLoading }
  // );

  const [data, setData] = useState({});

  const gestionar = () => {
    if (
      !(
        data.EVE_MonitorAlarmado &&
        data.EVE_TicketProveedor &&
        data.EVE_AreaEspecialista &&
        data.EVE_TicketInterno &&
        data.EVE_DescripcionFalla
      )
    ) {
      swal("Deben llenarse todos los campos", "", "error");
    } else {
      updateEvent({
        Token,
        id,
        body: {
          ...data,
          EVE_Operador: Username,
        },
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      swal("Evento gestionado", "", "success");
      navigate("/eventos");
    }
    if (isError) {
      swal("No se ha podido gestionar el evento", "", "error");
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (!isLoading) {
      setData(dataQuery);
    }
  }, [dataQuery]);

  useEffect(() => {
    if (error) {
      swal("No se encontró el evento", "", "error");
      navigate("/eventos");
    }
  }, [error]);
  const columns = [
    {
      field: "EVE_FechaRegistro",
      headerName: "FechaEvento",
      width: 220,
    },
    {
      field: "EVE_SD_TicketCreado",
      headerName: "TicketCreado",
      width: 160,
    },
    {
      field: "EVE_Sensor",
      headerName: "EVE_Sensor",
      width: 150,
    },
    {
      field: "EVE_SD_EstadoTicket",
      headerName: "EstadoTicket",
      width: 150,
    },
    ,
    {
      field: "EVE_IPNodo",
      headerName: "EVE_IPNodo",
      width: 180,
    },
    {
      field: "EVE_DescripcionAlarma",
      headerName: "EVE_DescripcionAlarma",
      width: 900,
    },
    {
      field: "ROL",
      headerName: "ROL",
      width: 150,
    },
  ];
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Box sx={{ flexGrow: 1, ml: 1, mt: 1 }}>
          {/* Contenido */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Detalles del evento</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_ID_ActiveAlert"
                        value={data.EVE_ID_ActiveAlert || 0}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_ID_ActiveAlert: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_FechaRegistro"
                        value={data.EVE_FechaRegistro || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_FechaRegistro: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_FechaHoraAlerta"
                        value={data.EVE_FechaHoraAlerta || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_FechaHoraAlerta: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth
                        label="EVE_DescripcionAlarma"
                        value={data.EVE_DescripcionAlarma || ""}
                        focused
                        color="secondary"
                        onChange={(e) => setData(data)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_MensajeAlarma"
                        value={data.EVE_MensajeAlarma || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_MensajeAlarma: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        fullWidth
                        label="EVE_Severidad"
                        value={data.EVE_Severidad || ""}
                        focused
                        color="secondary"
                        onChange={(e) => setData(data)}
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_Clasification"
                        value={data.EVE_Clasification || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_Clasification: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_TipoSensor"
                        value={data.EVE_TipoSensor || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_TipoSensor: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_IDSensor"
                        value={data.EVE_IDSensor || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_IDSensor: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_Sensor"
                        value={data.EVE_Sensor || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_Sensor: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        disabled
                        fullWidth
                        label="EVE_IPNodo"
                        value={data.EVE_IPNodo || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_IPNodo: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        value={data.EVE_Caption || ""}
                        label="EVE_Caption"
                        focused
                        color="secondary"
                        onChange={(e) => setData(data)}
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        fullWidth
                        value={data.EVE_MachineType || ""}
                        label="EVE_MachineType"
                        focused
                        color="secondary"
                        onChange={(e) => setData(data)}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        size="small"
                        disabled
                        value={data.EVE_Ubicacion || ""}
                        label="EVE_Ubicacion"
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_Ubicacion: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        fullWidth
                        focused
                        color="secondary"
                        onChange={(e) => setData(data)}
                        value={data.EVE_ORN_EntityCaption || ""}
                        label="EVE_ORN_EntityCaption"
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        fullWidth
                        focused
                        color="secondary"
                        onChange={(e) => setData(data)}
                        value={data.EVE_Negocio || ""}
                        label="EVE_Negocio"
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        fullWidth
                        focused
                        color="secondary"
                        onChange={(e) => setData(data)}
                        value={data.EVE_TipoSede || ""}
                        label="EVE_TipoSede"
                      />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        size="small"
                        fullWidth
                        focused
                        color="secondary"
                        onChange={(e) => setData(data)}
                        value={data.EVE_Proveedor || ""}
                        label="EVE_Proveedor"
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={6}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Cantidad de eventos en el tiempo</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SensorBehavior
                        sensor={data.EVE_Sensor || ""}
                        nodo={data.EVE_IPNodo || ""}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={6}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Campos requeridos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        value={data.EVE_MonitorAlarmado || ""}
                        label="EVE_MonitorAlarmado"
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_MonitorAlarmado: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        value={data.EVE_TicketProveedor || ""}
                        label="EVE_TicketProveedor"
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_TicketProveedor: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        value={data.EVE_TicketInterno || ""}
                        label="EVE_TicketInterno"
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_TicketInterno: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        value={data.EVE_AreaEspecialista || ""}
                        label="EVE_AreaEspecialista"
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_AreaEspecialista: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        label="EVE_DescripcionFalla"
                        value={data.EVE_DescripcionFalla || ""}
                        onChange={(e) =>
                          setData({
                            ...data,
                            EVE_DescripcionFalla: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={6}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Cantidad de eventos por hora</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SensorBehaviorHour
                        sensor={data.EVE_Sensor || ""}
                        nodo={data.EVE_IPNodo || ""}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Eventos relacionado</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow sx={{ backgroundColor: "#999" }}>
                              <TableCell>Fuente</TableCell>
                              <TableCell>IP</TableCell>
                              <TableCell>ID del evento</TableCell>
                              <TableCell>
                                Hora del evento (formato corto)
                              </TableCell>
                              <TableCell>Nombre del evento</TableCell>
                              <TableCell>Resultado del ping</TableCell>
                              <TableCell>Resultado Remediación</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {events.map((event) => (
                              <TableRow key={event.id}>
                                <TableCell>{event.source}</TableCell>
                                <TableCell>
                                  {data.EVE_IPNodo ? data.EVE_IPNodo : event.ip}
                                </TableCell>
                                <TableCell>{event.id}</TableCell>
                                <TableCell>{event.time}</TableCell>
                                <TableCell>{event.name}</TableCell>
                                <TableCell>{event.pingResult}</TableCell>
                                <TableCell>{event.remediationResult}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* <Grid item xs={6}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>
                    Otros sensores que presentaron alarma en ese momento
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SensoresNodo
                        fechaHoraAlerta={data.EVE_FechaHoraAlerta || ""}
                        nodo={data.EVE_IPNodo || ""}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={6}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>
                    Porcentaje de alertas de cada sensor para el nodo
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SensoresNodoGrafica
                        fechaHoraAlerta={data.EVE_FechaHoraAlerta || ""}
                        nodo={data.EVE_IPNodo || ""}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid> */}
          </Grid>
          {/* Fabs */}
          <Fab
            variant="extended"
            color="secondary"
            aria-label="volver"
            onClick={() => {
              navigate("/eventos");
            }}
            style={{
              margin: 0,
              top: "auto",
              right: 200,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
          >
            <ArrowBack sx={{ mr: 1 }} />
            Volver
          </Fab>
          <Fab
            variant="extended"
            color="secondary"
            aria-label="gestionar"
            onClick={gestionar}
            style={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
          >
            <BuildOutlined sx={{ mr: 1 }} />
            Gestionar
          </Fab>
        </Box>
      )}
    </Grid>
  );
};

import React from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import crearUsuario from "../../assets/images/add-user.png";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import clsx from "clsx";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  CircularProgress,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import {
  useCreateUserMutation,
  useGetUsersQuery,
  useDeleteUserMutation,
  useActiveUserMutation,
} from "../../features/api/apiYeapGo";
import { CheckCircleOutline, HighlightOffOutlined } from "@mui/icons-material";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
export const Users = () => {
  /* Login */
  const [user] = useAtom(userAtom);
  const { Token } = user;
  const Auth = Token;
  const { data, isLoading } = useGetUsersQuery({ Token: Auth });
  // const [action, setAction] = useState("crear");
  const [createUser] = useCreateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [activeUser] = useActiveUserMutation();
  //const [updateUser] = useUpdateUserMutation();
  let navigate = useNavigate();
  React.useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (user.Rol !== "Administrador") {
      swal(
        "Sin permisos suficientes",
        "Para gestionar usuarios debe ser Administrador",
        "error"
      );
      navigate("/");
    }
  }, [user]);

  const [dataUser, setDataUser] = useState({
    usuario: "",
    password: "",
    rol: "",
    nombres_apellidos: "",
    estado: "",
  });

  /* table columns */
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "usuario",
      headerName: "Usuario",
      width: 120,
    },

    {
      field: "nombres_apellidos",
      headerName: "Nombres",
      width: 200,
    },
    {
      field: "rol",
      headerName: "Rol",
      width: 125,
    },
    {
      field: "estado",
      headerName: "Estado",
      width: 100,
      cellClassName: (params) => {
        if (params.value == null) {
          return "";
        }
        return clsx("super-app", {
          active: params.value === "ACTIVO",
          inactive: params.value === "INACTIVO",
        });
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        const eliminarUsuario = (e) => {
          e.stopPropagation();
          deleteUser({ Token: Auth, body: { id: params.row.id } });
        };
        const activarUsuario = (e) => {
          e.stopPropagation();
          activeUser({ Token: Auth, body: { id: params.row.id } });
        };
        return (
          <>
            {params.row.estado === "ACTIVO" ? (
              <Button
                variant="text"
                color="primary"
                startIcon={<HighlightOffOutlined />}
                onClick={eliminarUsuario}
              >
                DESHABILITAR
              </Button>
            ) : (
              <Button
                variant="text"
                color="secondary"
                startIcon={<CheckCircleOutline />}
                onClick={activarUsuario}
              >
                HABILITAR
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const rol = [
    {
      value: "Agente",
      label: "Agente",
    },
    {
      value: "Administrador",
      label: "Administrador",
    },
  ];

  /* Formularios */
  const [open, setOpen] = React.useState(false);

  const handleClose = (action) => {
    setOpen(false);
  };

  /* Validaciones */
  const validateForm = () => {
    if (
      dataUser.usuario === "" ||
      dataUser.password === "" ||
      dataUser.rol === "" ||
      dataUser.nombres_apellidos === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  /* Crear Usuario */
  const CrearUsuario = async () => {
    if (validateForm()) {
      setDataUser({
        ...dataUser,
        estado: "ACTIVO",
      });
      createUser({
        Token: user.Token,
        body: dataUser,
      })
        .unwrap()
        .then(() => {
          setOpen(false);
          setDataUser({
            usuario: "",
            password: "",
            rol: "",
            nombres_apellidos: "",
            estado: "",
          });
          swal({
            title: "Usuario Creado Correctamente",
            icon: "success",
          });
        })
        .catch((err) => {
          swal({
            title: `Hubo un error al crear el usuario ${err}`,
            icon: "error",
          });
        });
    } else {
      swal({
        title: "Por favor ingrese todos los datos.",
        icon: "error",
      });
    }
  };
  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} md={3} sx={{ height: 300, width: "100%" }}>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              width="100%"
              image={crearUsuario}
              alt="Crear Usuario"
              onClick={() => {
                setOpen(true);
                // setAction("crear");
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Crear Usuario
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

      {/* Tabla */}
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          height: 300,
          width: "100%",
          "& .super-app-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.3)",
          },
          "& .super-app.inactive": {
            color: "#e5007c",
          },
        }}
      >
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <DataGrid
              density={"compact"}
              autoHeight
              rows={data || []}
              pageSize={10}
              columns={columns}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.id}
              // onSelectionModelChange={(e) => setEventsID(e)}
            />
          </>
        )}
      </Grid>

      {/* Formularios */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle>
          <DialogTitle id="scroll-dialog-title">CREAR USUARIO</DialogTitle>
        </DialogTitle>
        <DialogContent style={{ cursor: "pointer" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "2%",
              }}
            >
              <TextField
                fullWidth
                required
                onChange={(e) =>
                  setDataUser({
                    ...dataUser,
                    usuario: e.target.value,
                  })
                }
                label="USUARIO"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                onChange={(e) =>
                  setDataUser({
                    ...dataUser,
                    password: e.target.value,
                  })
                }
                label="CONTRASEÑA"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                select
                value={dataUser.rol}
                label="ROL"
                onChange={(e) =>
                  setDataUser({
                    ...dataUser,
                    rol: e.target.value,
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SupervisorAccountIcon />
                    </InputAdornment>
                  ),
                }}
              >
                {rol.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                onChange={(e) =>
                  setDataUser({
                    ...dataUser,
                    nombres_apellidos: e.target.value,
                  })
                }
                label="NOMBRES Y APELLIDOS"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DriveFileRenameOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => CrearUsuario(dataUser)}
          >
            Crear usuario
          </Button>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import { STAnalistLeaderboard } from "./STAnalistLeaderboard";
import { STEventsTime } from "./STEventsTime";
import { STAnalistTimeLeaderboard } from "./STAnalistTimeLeaderboard";

export const StatisticsAnalists = () => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});

  const toggle = () => setOpen(!open);

  return (
    <Grid
      container
      direction="row"
      justifyContent="start"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <STAnalistLeaderboard dateRange={dateRange}></STAnalistLeaderboard>
      </Grid>
      <Grid item xs={12} md={6}>
        <STAnalistTimeLeaderboard
          dateRange={dateRange}
        ></STAnalistTimeLeaderboard>
      </Grid>
      <Grid item xs={12} md={12}>
        <STEventsTime dateRange={dateRange}></STEventsTime>
      </Grid>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="volver"
        onClick={toggle}
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        Establecer rango de fecha
      </Fab>
      <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          {"Selecciona el rango de fecha"}
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

import ReactApexChart from "react-apexcharts";
import { useGetSensorHourlineQuery } from "../../features/api/apiYeapGo";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";

export const SensorBehaviorHour = ({ nodo, sensor }) => {
  const [user] = useAtom(userAtom);
  const { Token } = user;
  const { data: timelineData } = useGetSensorHourlineQuery({
    Token,
    nodo,
    sensor,
  });
  const state = {
    series: [
      {
        name: "Alertas",
        data: timelineData || [],
      },
    ],
    options: {
      chart: {
        type: "area",
        stacked: false,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: `Sensor ${sensor}-nodo ${nodo}`,
        align: "left",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        min: 0,
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        x: {
          format: "HH:mm",
        },
      },
    },
  };
  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="area"
      height={350}
    />
  );
};

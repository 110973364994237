import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Chart from "react-apexcharts";
import { useGetAnalistAlertsStatisticsQuery } from "../../features/api/apiYeapGo";
import Moment from "moment";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
export const STAnalistLeaderboard = ({ dateRange }) => {
  const [date, setDate] = useState({
    fechaInicial: "2020-1-01",
    fechaFinal: "3000-12-01",
  });
  const [analistList, setAnalistList] = useState([]);
  const [user] = useAtom(userAtom);
  const { Token } = user;
  const { data, isLoading } = useGetAnalistAlertsStatisticsQuery({
    Token,
    ...date,
  });
  useEffect(() => {
    if (data) {
      let Analistas = [...new Set(data?.map((d) => d.Analista))];
      let li = [];
      for (let index = 0; index < Analistas?.length; index++) {
        let lTickets = data.filter((d) => d.Analista === Analistas[index]);
        let objTickets = { Analista: Analistas[index] };
        lTickets.forEach(
          (d) => (objTickets[d.TipoTicket] = d.AlertasGestionadas)
        );
        li.push(objTickets);
      }
      li.sort(
        (a, b) =>
          b.Ticket + b.CCT + b.Informativo - (a.Ticket + a.CCT + a.Informativo)
      );
      setAnalistList(li.slice(0, 10));
    }
  }, [data]);

  useEffect(() => {
    if (dateRange.startDate) {
      setDate({
        fechaInicial: Moment(dateRange.startDate).format("YYYY-MM-DD"),
        fechaFinal: Moment(dateRange.endDate).format("YYYY-MM-DD"),
      });
    }
  }, [dateRange]);

  const state = {
    series: [
      {
        name: "Ticket",
        data: analistList?.map((d) => d.Ticket ?? 0) || [],
      },
      {
        name: "CCT",
        data: analistList?.map((d) => d.CCT ?? 0) || [],
      },
      {
        name: "Informativo",
        data: analistList?.map((d) => d.Informativo ?? 0) || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
          horizontal: true,
        },
      },
      dataLabels: { enabled: false },
      xaxis: {
        categories: analistList?.map((d) => d.Analista ?? "") || [],
      },
    },
  };

  return (
    <Accordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography gutterBottom variant="h5" component="div">
          Alertas gestionadas por analistas
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : data?.length > 0 ? (
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            height={"400rem"}
          />
        ) : (
          <>No hay datos disponibles</>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

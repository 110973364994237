/* eslint-disable react-hooks/exhaustive-deps */
import {
  useGetEventsQuery,
  useGetSeveridadesQuery,
} from "../../features/api/apiYeapGo";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CircularProgress, Fab, Grid } from "@mui/material";
import { BuildOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { DialogEvents } from "./DialogEvents";
import { userAtom } from "../../features/user/userAtom";
import { useAtom } from "jotai";

export const Events = () => {
  const [user, setUser] = useAtom(userAtom);
  const Auth = user.Token;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [eventsID, setEventsID] = useState([]);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetEventsQuery(Auth, {
    pollingInterval: 1000 * 5,
  });

  const { data: severidades, isLoading: isLoadingSeveridades } =
    useGetSeveridadesQuery(
      { Token: Auth },
      {
        pollingInterval: 1000 * 5,
      }
    );

  useEffect(() => {
    if (error?.originalStatus === 401) {
      swal("Sesión expirada", "Por favor vuelve a iniciar sesión", "warning");
      setUser({});
    }
  }, [error]);

  const columns = [
    {
      field: "EVE_ID_ActiveAlert",
      headerName: "EVE_ID_ActiveAlert",
      width: 160,
    },

    {
      field: "EVE_FechaHoraAlerta",
      headerName: "EVE_FechaHoraAlerta",
      width: 160,
    },
    {
      field: "EVE_DescripcionAlarma",
      headerName: "EVE_DescripcionAlarma",
      width: 700,
    },
    {
      field: "EVE_MensajeAlarma",
      headerName: "EVE_MensajeAlarma",
      width: 750,
    },
    {
      field: "EVE_Severidad",
      headerName: "EVE_Severidad",
      width: 170,
    },
    {
      field: "EVE_TipoEvento",
      width: 170,
      headerName: "EVE_TipoEvento",
    },
    {
      field: "EVE_Clasification",
      headerName: "EVE_Clasification",
      width: 170,
    },
    {
      field: "EVE_TipoSensor",
      headerName: "EVE_TipoSensor",
      width: 170,
    },

    {
      field: "EVE_Sensor",
      headerName: "EVE_Sensor",
      width: 250,
    },
    {
      field: "EVE_IPNodo",
      headerName: "EVE_IPNodo",
      width: 170,
    },
    {
      field: "EVE_Caption",
      headerName: "EVE_Caption",
      width: 500,
    },
    {
      field: "EVE_Ubicacion",
      headerName: "EVE_Ubicacion",
      width: 170,
    },
  ];

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <DataGrid
            autoHeight
            getRowHeight={() => "auto"}
            rows={
              filter !== ""
                ? data.filter((ev) => ev.EVE_Severidad === filter)
                : data || []
            }
            pageSize={25}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(e) => {
              navigate(`${e.id}`);
            }}
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row.EVE_ID_ActiveAlert}
            onSelectionModelChange={(e) => setEventsID(e)}
          />
        </>
      )}
      <DialogEvents
        open={dialogOpen}
        setOpen={setDialogOpen}
        eventsID={eventsID}
      />
      <Fab
        variant="extended"
        color="secondary"
        aria-label="gestionar"
        onClick={() => {
          setDialogOpen(true);
        }}
        style={{
          margin: 0,
          top: "auto",
          left: 250,
          bottom: 20,
          position: "fixed",
        }}
      >
        <BuildOutlined sx={{ mr: 1 }} />
        Gestionar
      </Fab>

      {!isLoadingSeveridades ? (
        <>
          {" "}
          <Fab
            variant="extended"
            color="warning"
            aria-label="WARNING"
            onClick={() => {
              if (filter === "") {
                setFilter("WARNING");
              } else {
                setFilter("");
              }
            }}
            style={{
              margin: 0,
              right: 20,
              top: 100,
              left: "auto",
              position: "fixed",
            }}
          >
            WARNING:
            {severidades?.filter((s) => s.Severidad === "WARNING")[0]
              ?.Cantidad || 0}
          </Fab>
          <Fab
            variant="extended"
            color="error"
            aria-label="CRITICAL"
            onClick={() => {
              if (filter === "") {
                setFilter("CRITICAL");
              } else {
                setFilter("");
              }
            }}
            style={{
              margin: 0,
              right: 160,
              top: 100,
              left: "auto",
              position: "fixed",
            }}
          >
            CRITICAL:{" "}
            {severidades?.filter((s) => s.Severidad === "CRITICAL")[0]
              ?.Cantidad || 0}
          </Fab>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};
